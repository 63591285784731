<template>
	<div class="summary-container">
		<div class="summary-container__content">
			<GlobalTitleComponent></GlobalTitleComponent>
			<div class="summary-container__info-containers">
				<StepSummaryComponentLeftSectionPartial />
				<StepSummaryComponentRightSectionPartial />
			</div>
			<div class="summary-container__navigation">
				<div class="summary-container__navigation-buttons">
					<ButtonSecondary :text="'Poprzedni krok'" @click="getPreviousStep"></ButtonSecondary>
					<ButtonPrimary :text="'Prześlij zgłoszenie'" :loading="buttonLoader" :disabled="buttonLoader" @click="sendMail"></ButtonPrimary>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Notyf } from 'notyf'
import * as Sentry from '@sentry/browser'
import { IAttachment, IImage } from '../PhotoUploaderComponent/PhotoUploaderComponent.vue'
import { UserData } from '../EmailComponent/EmailComponent.vue'
import { IMealPickerOptions, INode, INodeHistory } from '~/types/nodeTypes'
import { adjacencyList } from '~/data/adjacencyListGraph'
import { NodeKeys } from '~/data/keys'
import 'notyf/notyf.min.css'

const notyf = new Notyf({
	position: {
		x: 'center',
		y: 'top',
	},
	duration: 2000,
})

const { changeSiteState, getPhotosFromHistory, getEmailFromHistory, getDeliveryDateFromHistory, getPreviousStep, convertKeyToName } =
	useNavigationService()
const currentState = inject('currentState') as Ref<INode>
const siteStore = useSiteStore()
const buttonLoader = ref(false)
const history = ref<INodeHistory[] | null>()
const router = useRouter()

onMounted(() => {
	history.value = siteStore.getHistory
})

const sendDataToApi = async () => {
	const images = getPhotosFromHistory()
	const replyTo = (getEmailFromHistory() as UserData).email

	const imgs = await processImages(images as IImage[], replyTo)

	const data = createMailDataObject()

	try {
		await fetch('/api/complaint', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ data, images: imgs }),
		})
		getNextStep('Wysłano reklamację')
	} catch (e) {
		console.log(e)
	}
}

const sendMail = async () => {
	buttonLoader.value = true

	await sendDataToApi()

	const images = getPhotosFromHistory()
	const replyTo = (getEmailFromHistory() as UserData).email

	const attachments = await processImages(images as IImage[], replyTo)

	const id = (getEmailFromHistory() as UserData).numberID
	const config = useRuntimeConfig()

	if (config.public.APP_ENV === 'production' && window.location.hostname === config.public.APP_URL) {
		const msg = {
			to: config.public.APP_EMAIL,
			from: config.public.APP_EMAIL,
			replyTo,
			subject: 'Zgł. reklamacyjne od ' + replyTo + ` [Zam. #${id}]`,
			attachments,
			html: respectSpacesInMailBody(createMailBody()),
		}

		try {
			await $fetch('/api/mail', {
				method: 'POST',
				body: JSON.stringify(msg),
			})
			getNextStep('Wysłano maila')
		} catch (e) {
			Sentry.withScope((scope) => {
				scope.setUser({ email: replyTo })
				Sentry.captureException(e)
				const attachmentsContent: string[] = []
				msg.attachments.forEach((attachment) => {
					attachmentsContent.push(attachment.content?.substring(0, 50) as string)
				})
				Sentry.captureException(attachmentsContent)
			})
			notyf.error('Zgłoszenie nie zostało wysłane z powodu błędu. Skontaktuj się z Biurem Obsługi Klienta lub spróbuj ponownie')
		}
	} else {
		console.log('Nieprawidłowa subdomena - e-mail nie został wysłany.')
	}

	buttonLoader.value = false
}

const getNextStep = (message: string) => {
	const nodeKey = adjacencyList[currentState.value?.key]
	changeSiteState(nodeKey[0], message)
	router.push({ hash: `#${currentState.value.key}` })
}

const convertKeyToEnglishName = (label: string): string => {
	const translations = {
		Problem: 'problem',
		'Typ problemu': 'problem_type',
		'Posiłek niezgodny z zamówieniem': 'meal_issue',
		'Zła jakość posiłku': 'meal_issue',
		'Czy etykieta była naklejona?': 'was_label_attached',
		'Czy paczka była otwarta?': 'was_package_opened',
		Dieta: 'diet',
		Posiłki: 'meals',
		Opis: 'description',
		Podsumowanie: 'summary',
	}

	return translations[label] || label
}

const createMailDataObject = () => {
	if (!history.value) return {}

	const { email, numberID } = getEmailFromHistory() as UserData
	const deliveryDate = getDeliveryDateFromHistory() as Date

	const mailData = {
		email,
		numberID,
		deliveryDate: deliveryDate.toLocaleDateString('pl-PL'),
		details: {},
	}

	history.value.forEach((node) => {
		switch (node.key) {
			case NodeKeys.PICK_MEALS: {
				let all = ''
				if ((node.value as IMealPickerOptions).meals) {
					all += (node.value as IMealPickerOptions).meals.join(', ')
				}
				if (all.length > 0) all += ', '
				if ((node.value as IMealPickerOptions).additions) {
					all += (node.value as IMealPickerOptions).additions.join(', ')
				}
				mailData.details.meals = all
				break
			}

			case NodeKeys.PICK_DIET_MULTIPLE: {
				const diets = (node.value as string[]).join(', ')
				mailData.details.diet = diets
				break
			}

			default: {
				const value = node.value
				if (value instanceof Date || node.type === StepType.EMAIL) {
					break
				}
				if (Object.values(NodeKeys).includes(value as string)) {
					mailData.details[convertKeyToEnglishName(node.label)] = convertKeyToName(value)
				} else {
					mailData.details[convertKeyToEnglishName(node.label)] = node.value
				}
			}
		}
	})

	return mailData
}

const createMailBody = () => {
	if (!history.value) return ''
	let body = ''
	const { email, numberID } = getEmailFromHistory() as UserData
	body += 'Email => ' + `<b>${email}</b><br>`
	body += 'Numer zamówienia => ' + `<b>#${numberID}</b><br>`
	const deliveryDate = getDeliveryDateFromHistory() as Date
	body += `Data dostawy => <b>${deliveryDate.toLocaleDateString('pl-PL')}</b><br><br>`
	history.value.forEach((node) => {
		switch (node.key) {
			case NodeKeys.PICK_MEALS: {
				let all = ''
				if ((node.value as IMealPickerOptions).meals) all += (node.value as IMealPickerOptions).meals.join(', ')
				if (all.length > 0) all += ', '
				if ((node.value as IMealPickerOptions).additions) all += (node.value as IMealPickerOptions).additions.join(', ')
				body += `${node.label} => <b>${all}</b><br>`
				break
			}

			case NodeKeys.PICK_DIET_MULTIPLE: {
				const diets = (node.value as string[]).join(', ')
				body += `${node.label} => <b>${diets}</b><br>`
				break
			}

			case NodeKeys.REQUIRED_PHOTO:
			case NodeKeys.OPTIONAL_PHOTO:
			case NodeKeys.SUMMARY:
				break

			default: {
				const value = node.value
				if (value instanceof Date || node.type === StepType.EMAIL) {
					break
				}
				if (Object.values(NodeKeys).includes(value as string)) {
					body += `${node.label} => <b>${convertKeyToName(value)}</b><br>`
					break
				} else {
					body += `${node.label} => <b>${node.value}</b><br>`
				}
			}
		}
	})

	return body
}

const respectSpacesInMailBody = (body: string) => {
	return body.replace(/\n/g, '<br>')
}

const loadImageAsBase64 = (image: IImage) => {
	return new Promise((resolve, reject) => {
		if (image.image instanceof File) {
			const reader = new FileReader()

			reader.onloadend = () => {
				const imageData = String(reader.result).split(',')[1]

				const attachment = {
					filename: image.image.name,
					content: imageData,
				}

				resolve(attachment)
			}

			reader.onerror = reject
			reader.readAsDataURL(image.image as Blob)
		} else {
			reject(new Error('Invalid image format'))
		}
	})
}

const processImages = async (images: IImage[], replyTo: string): Promise<IAttachment[]> => {
	const attachments: IAttachment[] = []

	if (images) {
		const promises = images.map((image) => image.imagePreview !== '' && loadImageAsBase64(image))

		await Promise.allSettled(promises)
			.then((results) => {
				results.forEach((result) => {
					if (result.status === 'fulfilled') {
						attachments.push(result.value as IAttachment)
					} else {
						console.error(`An error occurred: ${result.reason}`)
					}
				})
			})
			.catch((error) => {
				Sentry.withScope((scope) => {
					scope.setUser({ email: replyTo })
					Sentry.captureException(error)
				})
				console.error('An error occurred:', error)
			})

		return attachments.filter((attachment) => attachment !== false)
	}

	return attachments
}
</script>

<style lang="scss">
@import './SummaryComponent.scss';
</style>
